.playground {
  border-radius: 3px;
  padding: 1rem;
  gap: 1rem;
  display: grid;
  grid-template-rows: 1fr 1fr;
  flex: 1 1 auto;
}

.playground .editor,
.playground .codemod-output {
  height: 50vh;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
}

.playground .editor.source-code {
  background-color: #e4fffb;
}

.playground .editor.codemod {
  background-color: #f2fff2;
}

.playground .user-inputs {
  display: grid;
  gap: 0 1rem;
  grid-template-columns: 1fr 1fr;
}

.playground .input-field {
  background-color: transparent;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
}

.playground .codemod-output {
  background-color: #feffe0;
}


@media (max-width: 767px) {
  .playground {
    grid-template-rows: 1fr;
  }

  .playground .user-inputs {
    grid-template-columns: 1fr;
  }
}
