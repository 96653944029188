body {
  background-color:#383a3a;
  font-family: 'Fira Code', 'Courier New', Courier, monospace;
  margin: 0;
}

main {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

h1 {
  margin: 0;
  background: #656565;
  padding: 0.5rem;
  box-shadow: 0 -3px 6px black inset;
}

h2 {
  margin: 0.5rem;
}

h1, h2 {
  color: #d7fff9;
  text-shadow: 3px 3px #a266a2, 6px 6px #4d4d46;
}
